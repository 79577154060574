/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  function stickyFooter () {
    var windowHeight = $(window).height()
    var bodyHeight = $('body').outerHeight()
    if (windowHeight > bodyHeight) {
      var hauteurMain = windowHeight - $('header').outerHeight() - $('footer').outerHeight()
      $('main').css('min-height', hauteurMain + 'px')
    }
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

        gform.addFilter('gform_datepicker_options_pre_init', function (optionsObj, formId, fieldId) {
          // Apply to field 2 only
          if (fieldId === 11 || fieldId === 12) {
            optionsObj.minDate = 0
          }
          return optionsObj
        })
        stickyFooter()

        $('.datetimepicker input').datetimepicker({
                                                    locale: 'fr',
                                                    format: 'DD-MM-YYYY',
                                                    minDate: new Date(),
                                                  })

        $('.datetimepickerHour input').datetimepicker({
                                                        locale: 'fr',
                                                        format: 'H:mm',
                                                      }
        )
      },
      finalize: function () {

        $('.match-height-row').matchHeight()
        $('.match-height-2').matchHeight()
        $('.match-height-3').matchHeight({
                                           byRow: false
                                         })

        if ($('.bloc-actu').length) {
          $('.bloc-actu').slick({
                                  arrows: false,
                                  adaptiveHeight: true,
                                  dots: true,
                                  infinite: false,
                                  autoplay: true,
                                  autoplaySpeed: 3000,
                                  slidesToShow: 3,
                                  speed: 2000,
                                  responsive: [
                                    {
                                      breakpoint: 991,
                                      settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2,
                                      }
                                    },
                                    {
                                      breakpoint: 600,
                                      settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1
                                      }
                                    },
                                    {
                                      breakpoint: 480,
                                      settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1
                                      }
                                    }]
                                })
        }

        if ($('#back-to-top').length) {
          var scrollTrigger = 100, // px
              backToTop     = function () {
                var scrollTop = $(window).scrollTop()
                if (scrollTop > scrollTrigger) {
                  $('#back-to-top').addClass('show-scroll')
                } else {
                  $('#back-to-top').removeClass('show-scroll')
                }
              }
          backToTop()
          $(window).on('scroll', function () {
            backToTop()
          })
          $('#back-to-top').on('click', function (e) {
            e.preventDefault()
            $('html,body').animate({
                                     scrollTop: 0
                                   }, 700)
          })
        }

        if (Cookies.get('popupStriebig') == null) {
          Cookies.set('popupStriebig', 'true', { expires: 7 })
          $('#popup').modal('show')
        }

        $('a[href^="#"]').click(function () {
          var $dom = $('html, body')
          $dom.animate({
                         scrollTop: $($.attr(this, 'href')).offset().top
                       }, 1000)
        })

        // JavaScript to be fired on all pages, after page specific JS is fired

        $('.owl-partners').owlCarousel({
                                         nav: true,
                                         loop: true,
                                         margin: 50,
                                         items: 6,
                                         autoplay: true,
                                         smartSpeed: 1000,
                                         autoplayTimeout: 2000,
                                         navText: ['<i class=" fa fa-chevron-circle-right"></i>', '<i class=" fa fa-chevron-circle-left"></i>'],

                                         responsive: {
                                           0: {
                                             items: 1
                                           },
                                           600: {
                                             items: 3
                                           },
                                           1000: {
                                             items: 5
                                           }
                                         }
                                       })
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
        $(document).ready(function () {
          //changement de l'url d'action du formulaire en homepage
          $('.form-devis').attr('action', '/devis/#gf_1')
        })
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    },
    'single_voyage': {
      init: function () {

      },
      finalize: function () {

        $(window).load(function () {
          var heightContent = $('.singleVoyageContent-voyage')

          if ($(window).width() > 991) {
            heightContent.css('min-height', heightContent.height() + 'px')
          }

          var stickyNavTop = $('.singleVoyageContent-voyageReservation').offset().top

          var stickyNav = function () {
            var scrollTop = $(window).scrollTop()
            var reservation = $('.singleVoyageContent-voyageReservation')

            if (scrollTop >= stickyNavTop && $(window).width() > 991) {
              var position = $('.singleVoyageContent-voyageDescription').offset()
              var size = $('.singleVoyageContent-voyage').width() / 3
              reservation.removeClass('relative')
              reservation.addClass('sticky-voyage')
              reservation.css({
                                right: position.left,
                                width: size + 'px',
                                top: ''
                              })

              var bottom = reservation.offset().top + reservation.outerHeight(true)
              if (bottom >= $('.adresse').offset().top) {
                var top = ($('.singleVoyageContent').outerHeight() + $('.singleVoyageArticle').outerHeight()) - reservation.outerHeight(true)
                reservation.removeClass('sticky-voyage')
                reservation.addClass('relative')
                reservation.css({
                                  top: top,
                                  width: size + 'px',
                                  right: 0,
                                })
              }
            } else {
              reservation.removeClass('sticky-voyage', 'relative')
              reservation.css({
                                right: '',
                                top: '',
                                width: ''
                              })
            }
          }

          if (stickyNavTop) {
            stickyNav()

            $(window).scroll(function () {
              stickyNav()
            })

            $(window).resize(function () {
              stickyNav()
              if ($(window).width() > 991) {
                heightContent.css('min-height', heightContent.height() + 'px')
              }
            })

            $(window).on('orientationchange', function () {
              stickyNav()

              if ($(window).width() > 991) {
                heightContent.css('min-height', heightContent.height() + 'px')
              }
            })
          }
        })

        $('.owl-voyage').owlCarousel({
                                       nav: false,
                                       loop: true,
                                       items: 1,
                                       autoplay: true,
                                       smartSpeed: 2500,
                                       dots: true
                                     })
      }
    },
    'allemand': {
      init: function () {
        $(window).load(function () {

          // STICKY MENU

          var stickyNavTop = $('.menu-allemand').offset().top

          var stickyNav = function () {
            var scrollTop = $(window).scrollTop()
            if (scrollTop > stickyNavTop) {
              $('.menu-allemand').addClass('sticky')
              $('header').css('padding-top', $('.menu-allemand').outerHeight())
            } else {
              $('.menu-allemand').removeClass('sticky')
              $('header').css('padding-top', '')
            }
          }

          if (stickyNavTop) {
            $(window).scroll(function () {
              stickyNav()
            })
          }
        })
      }
    }
  }

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire
      var namespace = Sage
      funcname = (funcname === undefined) ? 'init' : funcname
      fire = func !== ''
      fire = fire && namespace[func]
      fire = fire && typeof namespace[func][funcname] === 'function'

      if (fire) {
        namespace[func][funcname](args)
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common')

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm)
        UTIL.fire(classnm, 'finalize')
      })

      // Fire common finalize JS
      UTIL.fire('common', 'finalize')
    }
  }

  // Load Events
  $(document).ready(UTIL.loadEvents)

})(jQuery) // Fully reference jQuery after this point.
